import {gql} from "@apollo/client";

export const GET_WORK_LOG_GROUP_FORM = gql`
  query getWorkLogGroupForm {
    definitions {
      workLogGroupForm {
        title
        sections {
          title
          fields {
            key
            question
            definition
            type
            options {
              optionValue
              optionString
            }
            value
          }
        }
      }
    }
  }
`;
