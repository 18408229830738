import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useRef, useState} from "react";
import {
  Alert,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {PETS} from "../App";
import {ImageCropper} from "./ImageCropper/ImageCropper";
import {AnimalFormStyle} from "./PetForm";
import {useMutation} from "@apollo/client";
import {
  UPDATE_LOGGED_USER_ANIMAL_AVATAR
} from "../gql/mutations/updateLoggedUserAnimalAvatar";
import {GET_LOGGED_USER_ANIMALS} from "../gql/queries/getLoggedUserAnimals";
import AvatarEditor from "react-avatar-editor";

export default function PetAvatarEdit() {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const params = useParams();
  const {petId} = params;
  if (!petId || parseInt(petId) <= 0) {
    throw new Error('Invalid ID!');
  }
  const id = parseInt(petId);

  const [update] = useMutation(UPDATE_LOGGED_USER_ANIMAL_AVATAR, {
    refetchQueries: [
      GET_LOGGED_USER_ANIMALS
    ]
  });

  const image = useRef<AvatarEditor>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const img = image?.current?.getImageScaledToCanvas();
    if (img) {
      try {
        await update({variables: {
              animalId: id,
              avatar: img.toDataURL(),
            }
        });
        navigate('/' + PETS + '/edit/' + id);
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
    } else {
      setError('Upload file failed!');
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={() => navigate('/' + PETS + '/edit/' + id)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
          <Box component="form" onSubmit={handleSubmit} sx={AnimalFormStyle}>
            <ImageCropper ref={image} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Save
            </Button>
            {error ?
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
              : null}
          </Box>
      </Fade>
    </Modal>
  );
}
