import * as React from "react";
import Grid from "@mui/material/Grid";
import LocalForms from "./LocalForms/LocalForms";
import {
  isEvaluator,
  isOrganizationAdmin, isOrganizationInstructor
} from "../tools/permissionTools";
import {PadaExams} from "./PadaExams";
import {MyWorklogList} from "./MyWorklogList";
import {MyOrganizationWorklogList} from "./MyOrganizationWorklogList";
import {useLoggedUser} from "./Layout";
import {AasExams} from "./AasExams";
import {AatExams} from "./AatExams";


export default function Dashboard() {
  const { loggedUser } = useLoggedUser();
  const evaluator = isEvaluator(loggedUser);
  const instructor = isOrganizationInstructor(loggedUser);
  const organizationAdmin = isOrganizationAdmin(loggedUser);
  return  <Grid container spacing={1}>
    <LocalForms />
    {evaluator ? <PadaExams />: null}
    {instructor ? <AasExams />: null}
    {instructor ? <AatExams />: null}
    <MyWorklogList />
    {organizationAdmin ? <MyOrganizationWorklogList />: null}
  </Grid>
}
