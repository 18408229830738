import * as React from "react";
import {Field, UpdateFormState} from "../../types/Form";
import {ChangeEvent} from "react";
import optimizePhoto from "../../tools/photoOptimazer";
import Box from "@mui/material/Box";


function showFile(currVal: string) {
  const fullDisplay = (file: string) => {
    const myWindow = window.open();
    myWindow?.document.write('<img src=' + currVal + ' />');
  }
  return <a onClick={() => fullDisplay(currVal)}><img src={currVal} width="100px" height="100px"/></a>

}

const resizePhoto = (foto: File) => {

}

export function FormFieldFile(props: {
  field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  const update = (newFile: File) => {
    const data = optimizePhoto(newFile);
    data.then((value) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        updateFormState({
          value: {
            optionString: field.key,
            optionValue: typeof e.target?.result === 'string' ? e.target?.result : '',
          }
        })
      }
      if (newFile) {
        reader.readAsDataURL(value)
      }
    }, (error) => {alert('File upload not successful')});
  }
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      update(e.target.files[0]);
    }
  };
  const currVal = formState.form[field.key];
  return <div>
    <Box component="section" sx={{ width:150, p: 2, border: '1px dashed grey', borderRadius: 2 }}>
      <label htmlFor={"photo"}>Take a photo:</label>
    </Box>
      <input
          id={"photo"}
          type="file"
          accept="image/*"
          capture="enviroment"
          onChange={handleFileChange}
          hidden={true}
      />

    <Box component="section" sx={{ width:150, p: 2, border: '1px dashed grey', borderRadius: 2 }}>
      <label htmlFor={"upload"}>Upload a scan:</label>
    </Box>
      <input
          id={"upload"}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          hidden={true}
      />
    {currVal
        ? <div>{showFile(currVal)}</div>
        : <></>
    }
  </div>
}
