import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {AnimalFormStyle} from "./PetForm";
import {Fragment} from "react";
import {
  Backdrop,
  CircularProgress,
  Fade,
  Modal,
  Typography
} from "@mui/material";
import {PETS} from "../App";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  GET_LOGGED_USER_ANIMAL_DETAILS_BY_ID
} from "../gql/queries/getLoggedUserAnimalDetailsById";

type AnimalDetails = {
  workMinutes: number,
  worklogsCount: number
};

export default function PetDetails() {
  const params = useParams();
  const {petId} = params;

  if (!petId || parseInt(petId) <= 0) {
    throw new Error('Invalid ID!');
  }
  const navigate = useNavigate();

  const id = parseInt(petId);

  const {loading, data} = useQuery(
    GET_LOGGED_USER_ANIMAL_DETAILS_BY_ID,
    {variables: {petId: id}}
  );

  const animal: AnimalDetails = data ? data.loggedUser.animalById : null;


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={() => navigate('/' + PETS)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Box component="form" onSubmit={() => {}} sx={AnimalFormStyle}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography fontSize="larger">Animal ID:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontSize="larger">{id}</Typography>
            </Grid>
            {loading ? <CircularProgress/> : <Fragment>
              {animal.worklogsCount ?
              <Fragment>
                <Grid item xs={5}>
                  Hours worked:
                </Grid>
                <Grid item xs={7}>
                  {Math.floor(animal.workMinutes / 60)}h {animal.workMinutes%60}m
                </Grid>
                <Grid item xs={5}>
                  Times worked:
                </Grid>
                <Grid item xs={7}>
                  {animal.worklogsCount}
                </Grid>
              </Fragment> : <Fragment/>}
            </Fragment>}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
