import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {Field, UpdateFormState} from "../../types/Form";
import {useQuery} from "@apollo/client";
import {GET_INSTRUCTORS_LIST} from "../../gql/queries/getInstructorsList";

interface User {
  id: number;
  name: string;
}

interface LoggedUser {
  instructorList: User[]
}

interface Query {
  loggedUser: LoggedUser;
}

export default function FormFieldInstructor(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<string>('');
  const {loading, data} = useQuery<Query>(
    GET_INSTRUCTORS_LIST,
  );
  return (
    <Autocomplete
      id={field.key}
      key={field.key}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // autoSelect={true}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
      getOptionLabel={(option) => option?.name ?? ''}
      options={data?.loggedUser?.instructorList ?? []}
      loading={loading}

      // value={{id: formState.form[field.key], name:''}}
      onChange={(event, newValue:any) => {
        updateFormState({value: {
            optionString: field.key,
            optionValue: newValue.id.toString() ?? '',
        }})}}

      // inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}

      renderInput={(params) => (
        <TextField
          {...params}
          label="Instructor"

          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

}
