import {gql} from "@apollo/client";

export const GET_FINISHED_AAT_EXAMS = gql`
  query getAatExams {
    instructor {
      aatExams {
        id
        date
        instructorName
        instructorId
        dogName
        examPassed
        status
        certId
        specialization
      }
    }
  }
`;
