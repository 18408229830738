import {gql} from "@apollo/client";

export const GET_AAT_FORM = gql`
  query getAatForm {
    definitions {
      aatExamForm {
        title
        sections {
          title
          fields {
            key
            question
            definition
            type
            options {
              optionValue
              optionString
            }
            value
          }
        }
      }
    }
  }
`;
