import {gql} from "@apollo/client";

export const UPDATE_LOGGED_USER_ANIMAL = gql`
  mutation UpdateLoggedUserAnimal($animal: AnimalInput!) {
    loggedUser {
      updateAnimalById(animal: $animal) {
        id
        name
        dob
        species
      }
    }
  }
`;
