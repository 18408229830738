import {useApolloClient, useMutation} from "@apollo/client";
import {LOGOUT} from "../gql/mutations/logout";
import {useNavigate} from "react-router-dom";
import {GET_LOGGED_USER} from "../gql/queries/getLoggedUser";

export const useLogoutMutation = () => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [ mutation, mutationResults ] = useMutation( LOGOUT, {
    refetchQueries: [
      GET_LOGGED_USER
    ]
  });

  const logout = async () => {
    // Remove all data from the store since we are now logged out.
    await apolloClient.clearStore();
    // localStorage.clear();
    navigate('sign-in');
    return mutation();
  };

  return { logout, results: mutationResults };
};
