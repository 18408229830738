import * as React from 'react';
import Title from '../Title';
import {PadaFormUid} from "../PadaForm/PadaForm";
import {
  Paper
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import {WorkLogFormUid} from "../WorkLogForm/Individual/WorkLog";
import {WorkLogGroupFormUid} from "../WorkLogForm/Group/WorkLogGroup";
import {WorkLogSoloFormUid} from "../WorkLogForm/Volunteer/WorkLogSolo";
import WorkLogSoloLocalCard from './WorkLogSoloLocalCard';
import WorkLogGroupLocalCard from './WorkLogGroupLocalCard';
import WorkLogLocalCard from './WorkLogLocalCard';
import PadaLocalCard from './PadaLocalCard';
import {AasFormUid} from "../AasForm/Aas";
import AasLocalCard from "./AasLocalCard";

export function deleteLocalForm(key: string, setState: any) {
  localStorage.removeItem(key);
  setState(getStateFromLocalStorage());
}

function getStateFromLocalStorage() {
  const localData = [];
  for (let i in localStorage) {
    if (i.indexOf('FORMDATA') !== -1) {
      localData.push({
        key: i,
        data: localStorage.getItem(i),
      });
    }
  }
  return localData === [] ? null : localData;
}

export default function LocalForms() {
  const localData = getStateFromLocalStorage();

  const [state, setState] = useState(localData);
  return (
    state && state.length !== 0 ?
      <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Title>Locally stored data</Title>
      <Grid container spacing={4}>
        {state.map((data: any) => {
            if (data.key.indexOf(PadaFormUid) !== -1) {
              return <PadaLocalCard jsonData={data} setState={setState}
                                    key={data.key}/>;
            }
            if (data.key.indexOf(WorkLogFormUid) !== -1) {
              return <WorkLogLocalCard jsonData={data} setState={setState}
                                    key={data.key}/>;
            } if (data.key.indexOf(WorkLogGroupFormUid) !== -1) {
              return <WorkLogGroupLocalCard jsonData={data} setState={setState}
                                    key={data.key}/>;
            } if (data.key.indexOf(WorkLogSoloFormUid) !== -1) {
              return <WorkLogSoloLocalCard jsonData={data} setState={setState}
                                    key={data.key}/>;
            }
            if (data.key.indexOf(AasFormUid) !== -1) {
              return <AasLocalCard jsonData={data} setState={setState}
                                    key={data.key}/>;
            }
            return null;
          })
         }
      </Grid>
      </Paper>
    </Grid> : null
  );
}
