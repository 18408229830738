import {gql} from "@apollo/client";

export const GET_LOGGED_USER_WORKLOG_LIST = gql`
  query getLoggedIUserWorklogList {
    loggedUser {
      worklogList {
        id
        place
        date
        animalName
        animalId
        timeSpent
        notes
        worklogType
      }
    }
  }
`;
