import {useQuery} from "@apollo/client";
import {GET_DOG_BY_ID} from "../../gql/queries/getAnimalById";
import * as React from "react";
import TextField from "@mui/material/TextField";
import {CircularProgress, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Field, Update, UpdateFormState} from "../../types/Form";
import {getAgeStringFromDob, isPadaCompatibleDob} from "../../tools/datesTool";

export function FormFieldAnimal(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  const petId = parseInt(formState.form[field.key]);
  const {loading: animalLoading, data: animalData} = useQuery(
    GET_DOG_BY_ID,
    {
      variables: {petId: petId},
      skip: petId < 1,
      onCompleted: (animalData) => {
        updateFormState({value: {
          optionValue: animalData?.evaluator?.animalById?.dob,
          optionString: 'animalDoB',
    }});
      }
    }
  );
  return <React.Fragment key={field.key}>
    <TextField
      error={formState.errors[field.key]}
      margin="dense"
      fullWidth
      id={field.key}
      label={field.question}
      name={field.key}
      type="number"
      value={formState.form[field.key] ? formState.form[field.key] : ''}
      onChange={(event) => {
        updateFormState(Update(event));
      }}
    />
    {
      animalLoading ? <CircularProgress/> :
        animalData?.evaluator.animalById ?
          <Grid container spacing={1} sx={{p: 1}} fontSize={'small'}>
            <Grid item xs={6}>Handler Name</Grid>
            <Grid item
                  xs={6}>{animalData.evaluator.animalById.owner.firstName}</Grid>
            <Grid item xs={6}>Handler Surname</Grid>
            <Grid item
                  xs={6}>{animalData.evaluator.animalById.owner.lastName}</Grid>
            <Grid item xs={6}>Dog Name</Grid>
            <Grid item xs={6}>{animalData.evaluator.animalById.name}</Grid>
            <Grid item xs={6}>Dog DoB</Grid>
            <Grid item xs={6}>{animalData.evaluator.animalById.dob}</Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}><Typography color={!isPadaCompatibleDob(animalData.evaluator.animalById.dob) ? 'error' : 'normal'}>{getAgeStringFromDob(animalData.evaluator.animalById.dob)}</Typography></Grid>
            <Grid item xs={6}>Dog Breed</Grid>
            <Grid item xs={6}>{animalData.evaluator.animalById.breed}</Grid>
            <Grid item xs={6}>Chip No</Grid>
            <Grid item xs={6}>{animalData.evaluator.animalById.chipNo}</Grid>

          </Grid> :
          petId ? <Typography component="p" variant="h4" color="error">Dog {petId} does not exist!</Typography> : null
    }
  </React.Fragment>;
}
