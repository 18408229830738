import React, {Fragment, useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import SignIn from "./components/sign-in";
import SignUp from "./components/sign-up";
import {useQuery} from "@apollo/client";
import Layout from "./components/Layout";
import Pets from "./components/Pets";
import EditPetForm from "./components/EditPetForm";
import NewPetForm from "./components/NewPetForm";
import {GET_LOGGED_USER} from "./gql/queries/getLoggedUser";
import {isEvaluator, isOrganizationInstructor} from "./tools/permissionTools";
import NewPadaExam from "./components/PadaForm/NewPadaExam";
import EditPadaForm from "./components/PadaForm/EditPadaForm";
import {Outlet} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import PadaCertificate from "./components/PadaCertificate";
import ResetPasswordRequest from "./components/ResetPasswordRequest";
import ResetPasswordConfirmation from "./components/ResetPasswordConfirmation";
import EditUserProfile from "./components/EditUserProfile";
import NewWorkLog from "./components/WorkLogForm/Individual/NewWorkLog";
import EditWorkLogForm from "./components/WorkLogForm/Individual/EditWorklogForm";
import EmailConfirmation from "./components/EmailConfirmation";
import EditDbWorkLogForm from "./components/WorkLogForm/Individual/EditDbWorklogForm";
import PetDetails from "./components/PetDetails";
import EditDbPadaForm from "./components/PadaForm/EditDbPadaForm";
import PetAvatarEdit from "./components/PetAvatarEdit";
import WorklogDashboard from "./components/WorkLogForm/WorklogDashboard";
import NewWorkLogGroup from './components/WorkLogForm/Group/NewWorkLogGroup';
import EditWorkLogGroupForm from './components/WorkLogForm/Group/EditWorklogGroupForm';
import EditDbWorkLogGroupForm from './components/WorkLogForm/Group/EditDbWorklogGroupForm';
import NewWorkLogSolo from './components/WorkLogForm/Volunteer/NewWorkLogSolo';
import EditWorklogSoloForm
  from "./components/WorkLogForm/Volunteer/EditWorklogSoloForm";
import EditDbWorklogSoloForm
  from "./components/WorkLogForm/Volunteer/EditDbWorklogSoloForm";
import NewAas from "./components/AasForm/NewAas";
import EditAas from "./components/AasForm/EditAas";
import EditDbAas from "./components/AasForm/EditDbAas";
import NewAat from "./components/AatForm/NewAat";
import EditAat from "./components/AatForm/EditAat";
import EditDbAat from "./components/AatForm/EditDbAat";
import AasCertificate from './components/AasCertificate';
import AatCertificate from "./components/AatCertificate";
import AaeCertificate from "./components/AaeCertificate";

export const DASHBOARD = 'Dashboard';
export const PETS = 'Pets';
export const PADA_EXAM = 'Pada%20Exam';
export const AAS_EXAM = 'AAS%20Exam';
export const AAT_EXAM = 'AAT%20Exam';
export const PADA_CERTIFICATE = 'Pada%20Certificate';
export const AAS_CERTIFICATE = 'AAS%20Certificate';
export const AAT_CERTIFICATE = 'AAT%20Certificate';
export const AAE_CERTIFICATE = 'AAE%20Certificate';
export const EDIT_PROFILE = 'Edit%20Profile';
export const WORK_LOG = 'Work%20Log';

function App() {
  const {data} = useQuery(GET_LOGGED_USER);
  // const {loggedUser, setLoggedUser} = useLoggedUser();
  // if (data && data.loggedUser && !loggedUser) {
  //   setLoggedUser(data.loggedUser);
  // }
  const [loggedUser, setLoggedUser] = useState(null);
  useEffect(() => {
    if (data && data.loggedUser) {
      setLoggedUser(data.loggedUser);
    }
  }, [data])

  const evaluator = loggedUser ? isEvaluator(loggedUser) : false;
  const instructor = loggedUser ? isOrganizationInstructor(loggedUser) : false;

  if (!loggedUser) {
    return (
      <Routes>
        <Route path = "/sign-in" element={<SignIn />} />
        <Route path = "/sign-up" element={<SignUp />} />
        <Route path = "/password" element={<ResetPasswordRequest />} />
        <Route path = "/newPassword" element={<ResetPasswordConfirmation />} />
        <Route path = "/confirmEmail" element={<EmailConfirmation />} />
        <Route path = "*" element = {<SignIn />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path = "/sign-in" element={<SignIn />} />
      <Route path = {PADA_CERTIFICATE}>
        <Route path=":certId" element={<PadaCertificate />} />
      </Route><
      Route path = {AAS_CERTIFICATE}>
        <Route path=":certId" element={<AasCertificate />} />
      </Route>
      <Route path = {AAT_CERTIFICATE}>
        <Route path=":certId" element={<AatCertificate />} />
      </Route>
      <Route path = {AAE_CERTIFICATE}>
        <Route path=":certId" element={<AaeCertificate />} />
      </Route>
      <Route path = "/" element={<Layout loggedUser={loggedUser} />}>
        <Route path = {DASHBOARD} element={<Dashboard />} />
        <Route path = {EDIT_PROFILE} element={<EditUserProfile />} />
        <Route path = {PETS} element={<Pets />}>
          <Route path="edit/:petId" element={<EditPetForm />} />
          <Route path="see/:petId" element={<PetDetails />} />
          <Route path="avatar/:petId" element={<PetAvatarEdit />} />
          <Route path="add" element={<NewPetForm />} />
        </Route>
        <Route path = {WORK_LOG} element={<Outlet />}>
          <Route path ="new" element={<NewWorkLog />} />
          <Route path ="new_group" element={<NewWorkLogGroup />} />
          <Route path ="new_solo" element={<NewWorkLogSolo />} />
          <Route path="local/:uid" element={<EditWorkLogForm />} />
          <Route path="local_group/:uid" element={<EditWorkLogGroupForm />} />
          <Route path="local_solo/:uid" element={<EditWorklogSoloForm />} />
          <Route path="remote/:id" element={<EditDbWorkLogForm readOnly={false} />} />
          <Route path="remote_group/:id" element={<EditDbWorkLogGroupForm readOnly={false} />} />
          <Route path="remote_solo/:id" element={<EditDbWorklogSoloForm readOnly={false} />} />
          <Route path="see/:id" element={<EditDbWorkLogForm readOnly={true} />} />
          <Route path="see_group/:id" element={<EditDbWorkLogGroupForm readOnly={true} />} />
          <Route path="see_solo/:id" element={<EditDbWorklogSoloForm readOnly={true} />} />
          <Route index element={<WorklogDashboard />} />
        </Route>
        {evaluator ?
          <Route path = {PADA_EXAM} element={<Outlet />}>
            <Route path ="new" element={<NewPadaExam />} />
            <Route path="local/:uid" element={<EditPadaForm />} />
            <Route path="remote/:id" element={<EditDbPadaForm readOnly={false}/>} />
            <Route path="see/:id" element={<EditDbPadaForm readOnly={true} />} />
            <Route index element={<NewPadaExam />} />
          </Route>
          : null}
        {instructor ? <Fragment>
          <Route path={AAS_EXAM} element={<Outlet/>}>
            <Route path="new" element={<NewAas/>}/>
            <Route path="local/:uid" element={<EditAas/>}/>
            <Route path="remote/:id" element={<EditDbAas/>}/>
            <Route path="see/:id" element={<EditDbAas/>}/>
            <Route index element={<NewAas/>}/>
          </Route>
          <Route path = {AAT_EXAM} element={<Outlet/>}>
        <Route path="new" element={<NewAat/>}/>
        <Route path="local/:uid" element={<EditAat/>}/>
        <Route path="remote/:id" element={<EditDbAat/>}/>
        <Route path="see/:id" element={<EditDbAat/>}/>
        <Route index element={<NewAat/>}/>
      </Route>
        </Fragment>: null}
        <Route index element={<Dashboard />} />
      </Route>
      {/*<Route path = "test" element={<Test />}/>*/}
      <Route path = "*" element = {<Layout loggedUser={loggedUser} />} />
    </Routes>
  );
}

export default App;
