import * as React from 'react';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from '@mui/icons-material/Person';
import ListItemText from "@mui/material/ListItemText";
import PetsIcon from "@mui/icons-material/Pets";
import BadgeIcon from '@mui/icons-material/Badge';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import LogoutIcon from "@mui/icons-material/Logout";
import WorkIcon from '@mui/icons-material/Work';
import {Fragment, useState} from "react";
import {useLogoutMutation} from "../tools/useLogout";
import {Outlet, useOutletContext} from "react-router-dom";
import {
  AAS_EXAM, AAT_EXAM,
  DASHBOARD,
  EDIT_PROFILE,
  PADA_EXAM,
  PETS,
  WORK_LOG
} from '../App';
import Copyright from './Copyright';
import {isEvaluator, isOrganizationInstructor} from "../tools/permissionTools";
import {AppRegistration, Refresh} from "@mui/icons-material";
import {User} from "../types/User";
import { RouterLink } from './RouterLink';
import {useApolloClient} from "@apollo/client";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const linkStyles = {
  margin: 0,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: 1.43,
  letterSpacing: "0.01071em",
  color: "#2596be",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

interface DashboardParams {
  loggedUser: User|null
}

export function useLoggedUser() {
  return useOutletContext<DashboardParams>();
}

function DashboardContent({loggedUser}:DashboardParams) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { logout } = useLogoutMutation();
  const evaluator = isEvaluator(loggedUser);
  const instructor = isOrganizationInstructor(loggedUser);
  const client = useApolloClient();

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={drawerOpen}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {}
            </Typography>
            <IconButton color={"inherit"} onClick={() => client.resetStore()}>
              <Refresh />
            </IconButton>
            {/*<IconButton color="inherit">*/}
            {/*  <Badge badgeContent={0} color="secondary">*/}
            {/*    <NotificationsIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={drawerOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <RouterLink to={DASHBOARD} text={DASHBOARD} icon={<DashboardIcon />} />
            <RouterLink to={EDIT_PROFILE} text={"Edit Profile"} icon={<PersonIcon />} />
            <RouterLink to={PETS} text={PETS} icon={<PetsIcon />} />
            <RouterLink to={WORK_LOG} text={"WorkLog"} icon={<WorkIcon />} />
            {
              evaluator ?
                <RouterLink to={PADA_EXAM} text={"PADA Exam"} icon={<AppRegistration />} />
                : null
            }
            {
              instructor ? <Fragment>
                <RouterLink to={AAS_EXAM} text={"AAS Exam"} icon={<BadgeIcon />} />
                <RouterLink to={AAT_EXAM} text={"AAT Exam"} icon={<BadgeOutlinedIcon />} />
                </Fragment>
                : null
            }
            <Divider sx={{ my: 3 }} />
            <ListItemButton
              onClick={() => logout() }
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet context={{loggedUser}} />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
  );
}

export default function Layout(props:DashboardParams) {
  return <DashboardContent {...props} />;
}
