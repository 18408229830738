import {gql} from "@apollo/client";

export const GET_INSTRUCTOR_AAT_DATA = gql`
  query getInstructorAatData($examId: Int!) {
    instructor {
      aatExamData(examId: $examId) {
        key
        value
      }
    }
  }
`;
