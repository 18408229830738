import React, {useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import {useDropzone} from 'react-dropzone'
import {Paper, Slider} from "@mui/material";

export const ImageCropper = React.forwardRef((props, ref) => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({multiple:false});
  const [scale, setScale] = useState(1.0);

  return (
    <section className="container">
      {!acceptedFiles[0] ?
      <Paper
        {...getRootProps()}
        sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </Paper> :
        <section>
          {
          <AvatarEditor
            ref={ref}
            width={200}
            height={200}
            image={acceptedFiles[0]}
            borderRadius={100}
            scale={scale}
          />
          }
          <Slider
            aria-label='Scale'
            defaultValue={1.0}
            min={0.1}
            max={10}
            step={0.01}
            onChangeCommitted={(event, value) =>
              setScale(Array.isArray(value) ? value[0] : value)
          }
          />
        </section>
      }
    </section>
  );
});
