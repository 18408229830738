import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import Alert from "@mui/material/Alert";
import {useLocation, useNavigate} from "react-router-dom";
import Copyright from './Copyright';
import {EMAIL_CONFIRMATION} from "../gql/mutations/emailConfirmation";
import {CircularProgress} from "@mui/material";




export default function EmailConfirmation() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const selector = params.get('selector');
  const token = params.get('token');
  const [confirm] = useMutation(EMAIL_CONFIRMATION);

  useEffect(() => {
    if (!selector || !token) {
      setError('Could not generate proper request to confirm email address. Please try again to load a page from the email message');
      return;
    }
    confirm({
      variables: {
        selector: selector,
        token: token,
      }
    }).then(
      (response) => {
      setSuccess(true);
      setTimeout(() => navigate('/'), 5000);
    }, (e) => {
      setError(e.message);
    });
  }, [selector, token, confirm, navigate]);

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirming email address...<br/>
          </Typography>
          { error ?
            <Alert onClose={() => setError('')} severity="error">
              {error}
            </Alert>
          : success ?
              <Alert onClose={() => setSuccess(false)} severity="success">
                Success!
              </Alert>
          : <CircularProgress/>}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
