import dog from "../images/pies.jpg";
import cat from "../images/kot.jpg";

export type Animal = {
  id: number,
  name: string,
  officialName: null|string,
  breed: string,
  chipNo: null|string,
  dob: string,
  species: string,
  certificateList: null|string[],
  avatar: null|string
};

export const getAvatar = (animal: Animal|null) => {
  if (null === animal) {
    return dog;
  }
  if (animal.avatar) {
    return "data:image/png;base64, " + (animal.avatar);
  }
  return animal.species === 'cat' ? cat : dog;
}

export type AnimalList = [Animal];
