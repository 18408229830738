import * as React from 'react';
import {useMutation} from "@apollo/client";
import {GET_LOGGED_USER_ANIMALS} from "../gql/queries/getLoggedUserAnimals";
import { Animal } from '../types/Animal';
import PetForm from "./PetForm";
import {ADD_LOGGED_USER_ANIMAL} from "../gql/mutations/addLoggedUserAnimal";

export default function NewPetForm() {
  const animal:Animal = {
    id: 0,
    species: "dog",
    name: "",
    officialName: null,
    breed: "",
    chipNo: null,
    dob: new Date().toDateString(),
    certificateList: null,
    avatar: null,
  }

  const [update] = useMutation(ADD_LOGGED_USER_ANIMAL, {
    refetchQueries: [
      GET_LOGGED_USER_ANIMALS
    ]
  });


  return (
    <PetForm
      loading={false}
      animal={animal}
      onSubmit={update}
      id={0}
    />
  );
}
