import * as React from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {GET_LOGGED_USER_ANIMALS} from "../gql/queries/getLoggedUserAnimals";
import {
  GET_LOGGED_USER_ANIMAL_BY_ID
} from "../gql/queries/getLoggedUserAnimalById";
import { Animal } from '../types/Animal';
import {
  UPDATE_LOGGED_USER_ANIMAL
} from "../gql/mutations/updateLoggedUserAnimal";
import PetForm from "./PetForm";

export default function EditPetForm() {
  const params = useParams();
  const {petId} = params;

  if (!petId || parseInt(petId) <= 0) {
    throw new Error('Invalid ID!');
  }

  const id = parseInt(petId);

  const {loading, data} = useQuery(
    GET_LOGGED_USER_ANIMAL_BY_ID,
    {variables: {petId: id}}
  );

  const animal:Animal = data ? data.loggedUser.animalById : null;

  const [update] = useMutation(UPDATE_LOGGED_USER_ANIMAL, {
    refetchQueries: [
      GET_LOGGED_USER_ANIMALS
    ]
  });

  return (
    <PetForm
      loading={loading}
      animal={animal}
      onSubmit={update}
      id={id}
    />
  );
}
