import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Field,
  Form, FormState,
  FormStateUpdate,
  Section, UpdateFormState
} from "../../../types/Form";
import {useMutation} from "@apollo/client";
import Alert from "@mui/material/Alert";
import {useLocalStorage} from "../../../tools/useLocalStorage";
import { PETS } from '../../../App';
import {Snackbar} from "@mui/material";
import {FormPaperSection} from "../../Form/FormPaperSection";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {SAVE_WORKLOG_FORM} from "../../../gql/mutations/saveWorklogForm";
import {
  SAVE_WORKLOG_SOLO_FORM
} from "../../../gql/mutations/saveWorklogSoloForm";

type props = {
  uid:string,
  form: Form,
  initialFormData: any,
  readOnly: boolean,
}

export const WorkLogSoloFormUid = 'FORMDATA-WORKLOGSOLO-';
const nullableFields = ['name_and_position_present', 'notes_private', 'notes_to_institution'];

function useFormState(key: string, initialFormData: any): [FormState, UpdateFormState] {
  const [formState, setFormState] = useLocalStorage(key, initialFormData);
  // const [formState, setFormState] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  function updateFormState(update: FormStateUpdate) {
    if (update.value) {
      setFormState({...formState, ...{[update.value.optionString]: update.value.optionValue}});
    }
    if (update.error) {
      setErrors(update.error);
    }
  }
  return [{form: formState, errors: errors}, updateFormState];
}

function evaluateField(field: Field, formState: any) {
  return nullableFields.includes(field.key) || formState.form[field.key] !== null
}

function evaluateSection(section: Section, formState: any) {
  let errors = section.fields.reduce((curry, field) => {
    const valid = evaluateField(field, formState);
    if (!valid) {
      return {...curry, [field.key] : true};
    }
    return curry;
  }, {});
  return errors ? errors : false;
}



export default function WorkLogSolo(props: props) {
  const {uid, form, initialFormData, readOnly} = props;
  const navigate = useNavigate();
  const key = WorkLogSoloFormUid + uid;
  const [formState, updateFormState] = useFormState(key, initialFormData);
  const [error, setError] = useState('');

  function validateForm(form: Form, formState: any, updateFormState: UpdateFormState) {
    const errors = form.sections.reduce((curry, section) => {
      const errors = evaluateSection(section, formState);
      if (Object.keys(errors).length !== 0) {
        return {...curry, ...errors, [section.title]: true};
      }
      return curry;
    }, {});
    updateFormState({error: errors});
    const valid = Object.keys(errors).length === 0;
    if (!valid) {
      setError('Please fill up all required fields');
    }
    return valid
  }

  const [update] = useMutation(SAVE_WORKLOG_SOLO_FORM, {
    onCompleted(data) {
      if (data.loggedUser.saveWorklogSoloForm) {
        localStorage.removeItem(key);
        navigate('/' + PETS);
        return;
      }
      setError('something went wrong. Form not saved');
    }
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = validateForm(form, formState, updateFormState);
    if (valid) {
      try {
        let remoteId = formState.form['remoteId'];
        let keys = Object.keys(formState.form).filter(
          (key :string) => key !== 'remoteId'
        );
        await update({
          variables: {
            id: remoteId,
            fields: keys.map((key: string) => ({
              key: key,
              value: formState.form[key]
            }))
          }
        });
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
    }
  };

  return (
    <Box component="form" onSubmit={readOnly ? () => {} : handleSubmit}>
      <Typography variant={'h4'} component={'h4'}>{form.title}</Typography>
      <Grid container spacing={1}>
      {form.sections.map((section:Section) => (
        <Grid item xs={12} key={section.title}>
          <FormPaperSection
            key={section.title}
            section={section}
            formState={formState}
            updateFormState={updateFormState}
          />
        </Grid>
      ))}
      </Grid>
      {!readOnly
        ? <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Save & submit
          </Button>
        : <></>
      }
      <Snackbar open={error !== ''} autoHideDuration={5000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}
