import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useState} from "react";
import {useMutation} from "@apollo/client";
import Alert from "@mui/material/Alert";
import {Link, useNavigate} from "react-router-dom";
import Copyright from './Copyright';
import {linkStyles} from "./Layout";
import {RESET_PASSWORD_REQUEST} from "../gql/mutations/resetPasswordRequest";




export default function ResetPasswordRequest() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [reset] = useMutation(RESET_PASSWORD_REQUEST);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      await reset({
        variables: {
          email: formData.get('email'),
        }
      });
      navigate('/');
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password recovery
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            { error ?
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
              : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset password
            </Button>
            <Grid container>
              <Grid item>
                <Link to='/sign-in' style={linkStyles}>
                  {"Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
