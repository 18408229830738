import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import Aas, {AasFormUid} from "./Aas";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {GET_AAS_FORM} from "../../gql/queries/getAasForm";

export default function EditAas() {
  const params = useParams();
  const {uid} = params;

  if (!uid) {
    throw new Error('Invalid UID!');
  }

  const formData = localStorage.getItem(AasFormUid + uid);

  if (null === formData) {
    throw new Error('Invalid UID!');
  }

  const {loading, data} = useQuery(GET_AAS_FORM);

  return (
    <React.Fragment>
      {loading ? <CircularProgress /> : null}
      {!loading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
        : null }
      {data ? <Aas
        uid={uid}
        form={data.definitions.aasExamForm}
        initialFormData={JSON.parse(formData)}
      />: null
      }
    </React.Fragment>
  );
}
