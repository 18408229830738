import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {FormAccordionSection} from "../Form/FormAccordionSection";
import {
  Field,
  Form, FormState,
  FormStateUpdate,
  Section, UpdateFormState
} from "../../types/Form";
import {useMutation} from "@apollo/client";
import {SAVE_PADA_FORM} from "../../gql/mutations/savePadaForm";
import Alert from "@mui/material/Alert";
import {useLocalStorage} from "../../tools/useLocalStorage";
import {DASHBOARD} from '../../App';
import {Snackbar} from "@mui/material";
import {isPadaCompatibleDob} from "../../tools/datesTool";

type props = {
  readOnly: boolean,
  uid: string,
  form: Form,
  initialFormData: any,
}

export const PadaFormUid = 'FORMDATA-PADA-';
const nullableFields = ['comment', 'internal_comment', 'add_evaluator_id'];
const optionallyNullableFields = [
{
  field: '11_2',
  nullableValue: 'No jump',
  hiddenSection: 'Exercise 11a: Laying down on a BED: duration',
  nullableFields: ['11a_1', '11a_2', '11a_3', '11a_4', '11a_5', '11a_6', '11a_7', '11a_8', '11a_9'],
},
]

function fieldOptionallyNullable(field: Field, formState: any) {
  return optionallyNullableFields.filter(
    (condition) => {
      return formState.form[condition.field] === condition.nullableValue
        && condition.nullableFields.includes(field.key);
    }
  ).length !== 0;
}

function evaluateField(field: Field, formState: any) {
  return nullableFields.includes(field.key) || formState.form[field.key] !== null
    || fieldOptionallyNullable(field, formState);
}

function evaluateSection(section: Section, formState: any) {
  let errors = section.fields.reduce((curry, field) => {
    const valid = evaluateField(field, formState);
    if (!valid) {
      return {...curry, [field.key] : true};
    }
    return curry;
  }, {});
  return errors ? errors : false;
}

function useFormState(key: string, initialFormData: any): [FormState, UpdateFormState] {
  const [formState, setFormState] = useLocalStorage(key, initialFormData);
  // const [formState, setFormState] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  function updateFormState(update: FormStateUpdate) {
    if (update.value) {
      setFormState({...formState, ...{[update.value.optionString]: update.value.optionValue}});
    }
    if (update.error) {
      setErrors(update.error);
    }
  }
  return [{form: formState, errors: errors}, updateFormState];
}

const nonDisplayableSections = (formState: any) => {
  return optionallyNullableFields.filter((condition) => {
    return formState.form[condition.field] === condition.nullableValue
  }).map(condition => condition.hiddenSection);
}


export default function PadaForm(props: props) {
  const {readOnly, uid, form, initialFormData} = props;
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>("Candidate data");
  const key = PadaFormUid + uid;
  const [formState, updateFormState] = useFormState(key, initialFormData);
  const [error, setError] = useState('');

  function validateForm(form: Form, formState: any, updateFormState: UpdateFormState) {
    const aborted = formState.form.exam_passed === 'aborted';
    const errors = form.sections.reduce((curry, section) => {
      if ((!aborted && isPadaCompatibleDob(formState.form['animalDoB'])) ||  section.title === 'Candidate data' || section.title === 'Test summary') {
        const errors = evaluateSection(section, formState);
        if (Object.keys(errors).length !== 0) {
          return {...curry, ...errors, [section.title]: true};
        }
      }
      return curry;
    }, {});
    updateFormState({error: errors});
    const valid = Object.keys(errors).length === 0;
    if (!valid) {
      setError('Please fill up all required fields');
    }
    return valid
  }

  const handleSectionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {

      setExpanded(isExpanded ? panel : false);
    };

  const [update] = useMutation(SAVE_PADA_FORM, {
    onCompleted(data) {
      if (data.evaluator.savePadaForm) {
        localStorage.removeItem(key);
        navigate('/' + DASHBOARD);
        return;
      }
      setError('something went wrong. Form not saved');
    }
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = validateForm(form, formState, updateFormState);
    if (valid) {
      try {
        let remoteId = formState.form['remoteId'];
        let keys = Object.keys(formState.form).filter(
          (key :string) => key !== 'remoteId'
        );
        await update({
          variables: {
            id: remoteId,
            fields: keys.map((key: string) => ({key: key, value: formState.form[key]}))
          }
        });
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
    }
  };
  const doNotDisplay = nonDisplayableSections(formState);

  return (
    <Box component="form" onSubmit={readOnly ? () => {} : handleSubmit}>
      {form.sections.filter(section => !doNotDisplay.includes(section.title)).map((section:Section) => (
        <FormAccordionSection
          key={section.title}
          section={section}
          expanded={expanded === section.title}
          handleSectionChange={handleSectionChange}
          formState={formState}
          updateFormState={updateFormState}
        />))}
      {readOnly ? null :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{mt: 3, mb: 2}}
        >
          Save & submit
        </Button>
      }
      <Snackbar open={error !== ''} autoHideDuration={5000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}
