import {gql} from "@apollo/client";

export const GET_INSTRUCTOR_AAS_DATA = gql`
  query getInstructorAasData($examId: Int!) {
    instructor {
      aasExamData(examId: $examId) {
        key
        value
      }
    }
  }
`;
