import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Field, Update, UpdateFormState} from "../../types/Form";

export function FormFieldRadioRating(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  return <FormControl error={formState.errors[field.key]} fullWidth sx={{mb: 2}}>
    <FormLabel id={field.key}>
      <Typography key={'question'}>
        {field.question}
      </Typography>
    </FormLabel>
    {field.definition ?
      <Typography key={'definition_' + field.definition[0]} fontSize={"small"}>- {field.definition[0]}</Typography>
      : null}
    <RadioGroup
      name={field.key}
      value={formState.form[field.key] ? formState.form[field.key] : ''}
      onChange={(event) => updateFormState(Update(event))}
      sx={{
        '&>label:nth-of-type(even)': {background: 'rgba(0,0,0,.05)',},
      }}
    >
      {field.options?.map((option) => (
        <FormControlLabel value={option.optionValue}
                          key={option.optionValue}
                          control={<Radio size="small"/>}
                          label={option.optionString}/>
      ))}
    </RadioGroup>
    {field.definition ?
      <Typography key={'definition_' + field.definition[1]} fontSize={"small"}>- {field.definition[1]}</Typography>
      : null}
  </FormControl>;
}
