import * as React from 'react';
import {AAS_EXAM, WORK_LOG} from "../../App";
import {useNavigate} from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent, CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {DeleteForever, Restore} from "@mui/icons-material";
import {WorkLogSoloFormUid} from "../WorkLogForm/Volunteer/WorkLogSolo";
import {deleteLocalForm} from "./LocalForms";
import {AasFormUid} from "../AasForm/Aas";
import {useQuery} from "@apollo/client";
import {GET_DOG_BY_ID} from "../../gql/queries/getAnimalById";

export default function AasLocalCard({jsonData, setState}:any) {
  const id = jsonData.key.substring(AasFormUid.length);
  const json = JSON.parse(jsonData.data);
  const navigate = useNavigate();
  const petId = parseInt(json.animal_id);
  const {loading: animalLoading, data: animalData} = useQuery(
    GET_DOG_BY_ID,
    {
      variables: {petId: petId},
      skip: petId < 1,
    }
  );

  return <Grid item key={id} xs={12} sm={6} md={3}>
    <Card
      sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
    >
      <CardContent sx={{flexGrow: 1}}>
        <Typography gutterBottom variant="h6" component="h3">
          AAS Team exam:
          {animalLoading ? <CircularProgress/> :
            animalData?.evaluator.animalById ?
              `${animalData.evaluator.animalById.owner.lastName} ${animalData.evaluator.animalById.owner.firstName} with ${animalData.evaluator.animalById.name}`
              : "Animal not selected..."}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small"
                onClick={() => navigate('/' + AAS_EXAM + '/local/' + id)}><Restore/></Button>
        <Button size="small"
                onClick={() => deleteLocalForm(jsonData.key, setState)}><DeleteForever/></Button>
      </CardActions>
    </Card>
  </Grid>
}
