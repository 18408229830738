import {MobileTimePicker} from "@mui/lab";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {Field, UpdateFormState} from "../../types/Form";
import format from "date-fns/format";
import {TIME_FORMAT} from "../../tools/timeFormats";

export function FormFieldTime(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  const update = (newDate:Date|null) => {
    updateFormState({value: {
        optionString: field.key,
        optionValue: newDate ? format(newDate, TIME_FORMAT) : '',
      }})
  }
  const currVal = new Date("1900-01-01 " + formState.form[field.key]);
  return <MobileTimePicker
    label={field.question}
    value={currVal}
    onChange={(event) => update(event)}
    renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
  />;
}
