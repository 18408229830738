import {gql} from "@apollo/client";

export const ADD_LOGGED_USER_ANIMAL = gql`
  mutation AddLoggedUserAnimal($animal: AnimalInput!) {
    loggedUser {
      addNewAnimal(animal: $animal) {
        id
        name
        dob
        species
      }
    }
  }
`;
