import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useQuery} from "@apollo/client";
import {GET_LOGGED_USER_ANIMALS} from "../gql/queries/getLoggedUserAnimals";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import dog from '../images/pies.jpg';
import cat from '../images/kot.jpg';
import {useNavigate, Outlet} from "react-router-dom";
import {PADA_CERTIFICATE, PETS} from "../App";
import {Animal, getAvatar} from '../types/Animal';
import {AddCircle, Edit, Visibility, WorkspacePremium} from "@mui/icons-material";

export default function Pets() {
  const {loading, data} = useQuery(GET_LOGGED_USER_ANIMALS);
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {loading ? <CircularProgress /> : null}
      {!loading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
       : null }
      {data ?
        <Grid container spacing={4}>
          {data.loggedUser.animals.map((animal:Animal) => (
            <Grid item key={animal.id} xs={6} sm={4} md={3}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <CardMedia
                  component="img"
                  src={getAvatar(animal)}
                  alt="animal"
                  sx = {{
                    padding: "10px",
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {animal.name}
                  </Typography>
                  <Typography>
                    {animal.dob}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button sx={{minWidth: "25px"}} onClick={() => navigate('/' + PETS + '/see/' + animal.id)}><Visibility fontSize={"small"} /></Button>
                  <Button sx={{minWidth: "25px"}} onClick={() => navigate('/' + PETS + '/edit/' + animal.id)}><Edit fontSize={"small"} /></Button>
                  <Typography
                    noWrap
                    sx={{ flexGrow: 1 }}
                  >
                    {}
                  </Typography>
                  {
                    animal.certificateList?.map((id: string) => (
                      <Button sx={{minWidth: "30px"}} onClick={() => navigate('/' + PADA_CERTIFICATE + '/' + id)}><WorkspacePremium fontSize="large" color="success" /></Button>
                    ))
                  }
                </CardActions>
              </Card>
            </Grid>
          ))}
          <Grid item key={0} xs={6} sm={4} md={3}>
            <Card
              sx={{ height: '100%', display: 'flex', flexDirection: 'column', cursor:'pointer' }}
              onClick={() => navigate('/' + PETS + '/add')}
            >
              <CardMedia
                component="img"
                image={dog}
                alt="animal"
                sx={{
                  opacity: 0.5,
                  padding: "10px",
                }}
              />
              <AddCircle sx={{position: 'absolute', fontSize: '5em', m: '6%'}} color={"action"} />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  Add New
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid> : null
      }
      <Outlet />
    </React.Fragment>
  );
}
