export const ageNowFromDoB = (date: string|Date) => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return new Date(new Date().getTime() - new Date(date).getTime())
}

export const getAgeStringFromDob = (date: string|Date) => {
    const age = ageNowFromDoB(date)
    return age ? age.getUTCFullYear() - 1970 + ' years and ' + age.getUTCMonth() + ' months old' : ''
}

export const isPadaCompatibleDob= (date: string|Date) => {
    const age = ageNowFromDoB(date)
    return age ? age.getUTCFullYear() - 1970 >= 2 : false;
}

export const getAgeInYearsFromDob = (date: string|Date) => {
    const age = ageNowFromDoB(date)
    return age ? age.getUTCFullYear() - 1970 : 0;
}

export const getAgeInYearsFromDate = (date: Date) => {
    return date.getUTCFullYear() - 1970;
}