import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {useEffect} from "react";
import { GET_PADA_CERTIFICATE } from '../gql/queries/getPadaCertificate';
import  Background from '../../src/images/certificate_background.jpg'

export default function PadaCertificate() {
  const params = useParams();
  const {certId} = params;
  const navigate = useNavigate();

  if (!certId || parseInt(certId) <= 0) {
    throw new Error('Invalid ID!');
  }

  const {loading, data} = useQuery(
    GET_PADA_CERTIFICATE,
    {variables: {certId: certId}}
  );

  useEffect(() => {
    if (data && data.documents) {
      const myWindow = window.open();
      myWindow?.document.write(data.documents.padaCertificate.replace(/{{images}}/g, Background));
      navigate(-1);
    }
  }, [data, navigate]);

  return (
    loading ? <CircularProgress /> : null
  );
}
