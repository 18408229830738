import {
   Paper
} from "@mui/material";
import {FormField} from "./FormField";
import * as React from "react";
import {
  Section,
  Field,
  UpdateFormState,
} from "../../types/Form";
import {Warning} from "@mui/icons-material";
import {useRef} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type props = {
  section: Section,
  formState: any,
  updateFormState: UpdateFormState,
}

export function FormPaperSection(props: props) {
  const {
    section,
    formState,
    updateFormState,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
               key={section.title}
               ref={ref}>
      <Typography variant={'h5'} component={'h5'}>
        {formState.errors[section.title] ? <Warning color={"error"} /> : null}
        {section.title}
      </Typography>
      <Box sx={{
        // '&>div:nth-of-type(even)': {bgcolor: '#f0f0f0',},
        // '&>div' : {boxShadow: '1px 1px gray', borderRadius: '5px'},
        '&>div>label' : {fontVariant: 'small-caps'},
        '&>div>div' : {borderLeft: '5px solid', borderColor: 'secondary.main', paddingLeft: '5px'},
      }}>
        {section.fields.map((field: Field) => (
            <FormField
              field={field}
              formState={formState}
              updateFormState={updateFormState}
              key={field.key}
            />
          ))
        }
      </Box>
    </Paper>);
}
