import {gql} from "@apollo/client";

export const GET_LOGGED_USER_WORKLOG_SOLO_DATA = gql`
  query getLoggedIUserWorklogSoloData($worklogId: Int!) {
    loggedUser {
      worklogSoloData(worklogId: $worklogId) {
        key
        value
      }
    }
  }
`;
