import {gql} from "@apollo/client";

export const GET_LOGGED_USER_PROFILE = gql`
  query getLoggedUserProfile {
    loggedUser {
      id
      name
      firstName
      lastName
      email
    }
  }
`;
