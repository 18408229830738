import {gql} from "@apollo/client";

export const GET_DOG_BY_ID = gql`
  query getDogById($petId: Int!) {
    evaluator {
      animalById(animalId: $petId, kind: "dog") {
        id
        name
        officialName
        breed
        chipNo
        dob
        species
        owner {
          firstName
          lastName
          email
        }
      }
    }
  }
`;
