import Title from "./Title";
import Grid from "@mui/material/Grid";
import {CircularProgress, Paper} from "@mui/material";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridActionsCellItem, GridValueOptionsParams,
} from '@mui/x-data-grid';
import {
  AddCircleTwoTone, Edit, Preview, Visibility,
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {AAS_CERTIFICATE, AAS_EXAM} from "../App";
import {GET_FINISHED_AAS_EXAMS} from "../gql/queries/getFinishedAasExams";
import {CREATE_AAS_CERTIFICATE} from "../gql/mutations/createAasCertificate";

function Toolbar() {
  return (
    <GridToolbarContainer>
      {/* @ts-ignore */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* @ts-ignore */}
      {/*<GridToolbarDensitySelector />*/}
    </GridToolbarContainer>
  );
}

function DataTable(params: any) {
  const {data, canIssueCertificate, uId} = params;
  const navigate = useNavigate();
  const [createCertificate] = useMutation(CREATE_AAS_CERTIFICATE,
    {refetchQueries: [GET_FINISHED_AAS_EXAMS]}
  );
  const columns: GridColDef[] = React.useMemo(() => [
    { field: 'date', type: 'date', headerName: 'Date', width: 100 },
    { field: 'instructorName', headerName: 'Instructor name', width: 150 },
    { field: 'dogName', headerName: 'Dog name', width: 150 },
    {
      field: 'examPassed',
      type: 'boolean',
      headerName: 'Passed',
      width: 70,
      valueGetter: (params:GridValueOptionsParams) => params?.row?.examPassed === 'yes' ? 1 : 0,
    },
    {
      field: 'actions_cert',
      headerName: 'Certificate',
      type: 'actions',
      width: 100,
      getActions: (params:GridValueOptionsParams) => {
        const options = [];
        if (params?.row?.status === 'EXAM' && canIssueCertificate) {
          options.push(<GridActionsCellItem
            icon={<AddCircleTwoTone color={"primary"}/>}
            label="Create"
            onClick={() => createCertificate({variables: {examId: params?.row?.id}})}
          />);
        }
        if (params?.row?.status === 'CERTIFICATE') {
          options.push(<GridActionsCellItem
            icon={<Visibility color={"primary"}/>}
            label="See"
            onClick={() => navigate('/' + AAS_CERTIFICATE + '/' + params?.row?.certId)}
          />);
        }
        return options;
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 50,
      getActions: (params:GridValueOptionsParams) => {
        let options = [];
        if (uId && (params?.row?.instructorId === uId)) {
          options.push(<GridActionsCellItem
            icon={<Edit color={"primary"}/>}
            label="See Certificate"
            onClick={() => navigate('/' + AAS_EXAM + '/remote/' + params?.row?.id)}
          />);
        } else {
          options.push(<GridActionsCellItem
            icon={<Preview color={"primary"}/>}
            label="See Exam"
            onClick={() => navigate('/' + AAS_EXAM + '/see/' + params?.row?.id)}
          />);
        }
        return options;
      },
    },
  ], [navigate, canIssueCertificate, uId, createCertificate]);

  return data ? <div style={{ height: 400, width: '100%' }}><DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        components={{ Toolbar: Toolbar }}
  /></div>: null;
}

export function AasExams() {
  const {loading, data} = useQuery(GET_FINISHED_AAS_EXAMS);
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Title>Finished AAS exams</Title>
          {loading ? <CircularProgress /> : <DataTable
            data={data.instructor.aasExams}
            canIssueCertificate={true}
            uId={1}
          />}
      </Paper>
    </Grid>
  );
}
