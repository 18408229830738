import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useState} from "react";
import {useMutation} from "@apollo/client";
import {LOGIN} from "../gql/mutations/login";
import Alert from "@mui/material/Alert";
import {Link, useNavigate} from "react-router-dom";
import Copyright from './Copyright';
import {GET_LOGGED_USER} from "../gql/queries/getLoggedUser";
import {linkStyles} from "./Layout";
import ProjectsLogos from "./ProjectsLogos";




export default function SignIn() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [login] = useMutation(LOGIN, {
      refetchQueries: [
        GET_LOGGED_USER
      ]
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      await login({
        variables: {
          email: formData.get('email'),
          password: formData.get('password'),
          remember: !!formData.get('remember'),
        }
      });
      navigate('/');
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox name="remember" id="remember" value="remember" color="primary" />}
              label="Remember me"
            />
            { error ?
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
              : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/password" style={linkStyles}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to='/sign-up' style={linkStyles}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ProjectsLogos />
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
