import Title from "./Title";
import Grid from "@mui/material/Grid";
import {CircularProgress, Paper} from "@mui/material";
import * as React from "react";
import {useQuery} from "@apollo/client";
import {
  DataGrid, GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton, GridValueOptionsParams,
} from '@mui/x-data-grid';

import {
  GET_ORGANIZATION_WORKLOG_LIST
} from "../gql/queries/getOrganizationWorklogList";
import {AttachFile, Edit, Preview} from "@mui/icons-material";
import {WORK_LOG} from "../App";
import {useNavigate} from "react-router-dom";

function Toolbar() {
  return (
    <GridToolbarContainer>
      {/* @ts-ignore */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* @ts-ignore */}
      {/*<GridToolbarDensitySelector />*/}
    </GridToolbarContainer>
  );
}

const link = (type: string, id: string) => {
  let link = '/' + WORK_LOG + '/see';
  if(type === 'GROUP') {
    link = link + '_group';
  }
  if (type === 'SOLO') {
    link = link + '_solo'
  }
  return link + '/' + id.substring(1);
}

function DataTable(data: any) {
  const navigate = useNavigate();
  const columns: GridColDef[] = React.useMemo(() => [
    { field: 'date', type: 'date', headerName: 'Date', width: 100 },
    { field: 'userName', headerName: 'Name', width: 150 },
    { field: 'place', headerName: 'Place', width: 150 },
    { field: 'timeSpent', headerName: 'Time', width: 100 },
    { field: 'animalName', headerName: 'Animal name', width: 100 },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 60,
      getActions: (params: GridValueOptionsParams) => {
        const actions = [<GridActionsCellItem
          icon={<Preview color={"primary"}/>}
          label="See"
          onClick={() => navigate(link(params?.row?.worklogType, params?.row?.id))}
        />];
        if (params?.row?.scan) {
          actions.push(<GridActionsCellItem
            icon={<AttachFile color={"primary"}/>}
            label="See"
            onClick={() => navigate(link(params?.row?.worklogType, params?.row?.id))}
          />);
        }
        return actions;
      },
    }
  ], [navigate]);

  return data ? <div style={{ height: 400, width: '100%' }}><DataGrid
        rows={data.data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        components={{ Toolbar: Toolbar }}
  /></div>: null;
}

export function MyOrganizationWorklogList() {
  const {loading, data} = useQuery(GET_ORGANIZATION_WORKLOG_LIST);
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Title>My Organization Worklog</Title>
          {loading ? <CircularProgress /> : <DataTable data={data.organizationAdmin.worklogList} />}
      </Paper>
    </Grid>
  );
}
