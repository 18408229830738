import {
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Field, Update, UpdateFormState} from "../../types/Form";

export function FormFieldSelect(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  return <FormControl error={formState.errors[field.key]} fullWidth sx={{mb: 2}}>
    <InputLabel variant="standard" htmlFor="uncontrolled-native">
      <Typography key={'question'} sx={{paddingLeft: '10px'}}>
        {field.question}
      </Typography>
      {field.definition ? field.definition.map((value) => (
        <Typography key={'definition_' + value} fontSize={"small"}>{value}</Typography>)) : null}
    </InputLabel>
    <NativeSelect
      value={formState.form[field.key] ? formState.form[field.key] : ''}
      inputProps={{
        name: field.key,
        id: field.key
      }}
      onChange={(event) => updateFormState(Update(event))}
    >
      {field.options?.map((option) => (
        <option value={option.optionValue} key={option.optionValue}>
          {option.optionString}
        </option>
      ))}
    </NativeSelect>
  </FormControl>;
}
