import * as React from 'react';
import Typography from '@mui/material/Typography';
import {CircularProgress,} from "@mui/material";
import {useQuery} from "@apollo/client";
import {Form} from "../../../types/Form";
import {generateUniqueID} from "web-vitals/dist/lib/generateUniqueID";
import WorkLogSolo from "./WorkLogSolo";
import { GET_WORK_LOG_SOLO_FORM } from '../../../gql/queries/getWorkLogSoloForm';

export function getInitialState(data: Form) {
  let initialState: any = {};
  for (let section of data.sections) {
    for (let field of section.fields) {
      initialState[field.key] = field.value ?? null;
    }
  }
  return initialState;
}

export default function NewWorkLogSolo() {
  const uid = generateUniqueID();
  const {loading, data} = useQuery(GET_WORK_LOG_SOLO_FORM);

  return (
    <React.Fragment>
      {loading ? <CircularProgress /> : null}
      {!loading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
       : null }
      {data ? <WorkLogSolo
          uid={uid}
          form={data.definitions.workLogSoloForm}
          initialFormData={getInitialState(data.definitions.workLogSoloForm)}
          readOnly={false}
        />: null
      }
    </React.Fragment>
  );
}
